import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from './../assets/images/logo.png'
import logoTop from './../assets/images/logo-1.png'


const Navigation = () => {
    return (
        <Navbar expand="lg">
            <Container fluid>
                <Navbar.Brand href="/" className='brand-wide'><img src={logoTop} alt='' /></Navbar.Brand>
                <Navbar.Brand href="/" className='brand-shrink'><img src={logo} alt='' /></Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav navbarScroll>
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/AboutUs">About Us</Nav.Link>
                        <Nav.Link href="/Services">Services</Nav.Link>
                        <Nav.Link href="#">Products</Nav.Link>
                        <Nav.Link href="/ConnectWithUs">Contact Us</Nav.Link>
                        {/* <NavDropdown title="Link" id="navbarScrollingDropdown">
                            <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action4">
                                Another action
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action5">
                                Something else here
                            </NavDropdown.Item>
                        </NavDropdown> */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;