import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import one from './../assets/images/banner/1.jpg'
import two from './../assets/images/banner/2.jpg'
import three from './../assets/images/banner/3.jpg'
import four from './../assets/images/banner/4.jpg'
import { Autoplay, Pagination } from 'swiper/modules';

// Main App
const BannerSlide = () => {
  return (
    <div className="slider-container">
      <Swiper
        centeredSlides={true}
        autoplay={{
          delay: 5200,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
        loop
      >
        <SwiperSlide>
          <div>
            <img src={one} alt='' />
            <p>Technology is best when it brings people together</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="video-container">
            <img src={two} alt='' />
            <p>Expert IT Services for Businesses of All Sizes</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="video-container">
            <img src={three} alt='' />
            <p>Where Technology Meets Creativity</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="video-container">
            <img src={four} alt='' />
            <p>Empowering Businesses Through Technology</p>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default BannerSlide;