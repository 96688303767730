import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Button, Card, Col } from "react-bootstrap";

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';


const ContactForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_xo4nx2z', 'template_nx1lc9v', form.current, 'aVvwqdG6gpTTl3HAv')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };
    return (
        <Form ref={form} onSubmit={sendEmail}>
            <FloatingLabel
                controlId="floatingInput"
                label="Your Name"
                className="mb-3"
            >
                <Form.Control type="text" placeholder="name@example.com" name="user_name" required />
            </FloatingLabel>
            <FloatingLabel
                controlId="floatingInput"
                label="Email address"
                className="mb-3"
            >
                <Form.Control type="email" placeholder="name@example.com" name="user_email" required />
            </FloatingLabel>
            <FloatingLabel controlId="floatingTextarea2" label="Message">
                <Form.Control
                    as="textarea"
                    placeholder="Message"
                    style={{ height: '100px' }}
                    name="message"
                    required
                />
            </FloatingLabel>

            <Button type="submit" variant='outline-success'> Submit</Button>
        </Form>
    )
}

export default ContactForm