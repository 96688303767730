import React, { useEffect, useState } from 'react'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AboutUs from './AboutUs';
import Navigation from './Navigation';
import HomeContent from './Home';

import Footer from "./Footer";
import Services from './Services';
import Products from './Products';
import Contact from './Contact';

// Main App
const Welcome = () => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 30);
    });
  }, []);


  return (
    <div className={scroll ? "menubar-shrink" : "menubar-wide"}>
      <Router>
        <Navigation />
        <Routes>
          <Route exact path='/' element={<HomeContent />} />
          <Route path='/AboutUs' element={<AboutUs />} />
          <Route path='/Services' element={<Services />} />
          <Route path='/Products' element={<Products />} />
          <Route path='/ConnectWithUs' element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  )
}

export default Welcome;