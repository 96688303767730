// import ProductList from "./components/ProductList";
import Welcome from "./components/Welcome";
function App() {
  return (
    <div className="App">
      <Welcome />
      {/* <Welcome /> */}
    </div>
  );
}

export default App;
