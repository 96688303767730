import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from 'react-bootstrap/Card';
import whoWeAreBanner from './../assets/images/who-we-are.png'
import Button from 'react-bootstrap/Button';
import ScrollAnimation from "react-animate-on-scroll";


const HomeWhoWeAre = () => {
    return (
        <div className="who-we-are-content">

            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <Container>
                    <Row className="d-flex  align-items-center">
                        <Col lg={6}>

                            <Card className="banner-card">
                                <Card.Body>
                                    <h1>Who We Are</h1>
                                    <h2 className="py-3">We are your one stop solution for latest technology based services in IT</h2>
                                    <p>Welcome to <span className="brand-name">STADWTECH</span> Technology Private Limited your one-stop IT service provider specializing in Java-based Development, AWS Cloud, DevOps, Databases, and Web Development.</p>
                                    <a className="anchor-button outline-success"  href="/AboutUs">Know More</a>
                                </Card.Body>


                            </Card>
                        </Col>
                        <Col lg={6}>
                            <img src={whoWeAreBanner} alt="" />
                        </Col>
                    </Row>
                </Container>

            </ScrollAnimation>
        </div>
    )
}
export default HomeWhoWeAre;