import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ScrollAnimation from "react-animate-on-scroll";

const HomeHowWeWork = () => {
    return (
        <div className="how-we-work-content">
            <Container>
                <Row className="d-flex justify-content-center">
                    <Col sm={7}>
                        <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                            <h1>How We Work</h1>
                            <h2>We work by creating win-win relationship with our customers.</h2>
                            <a className="anchor-button outline-success"  href="/AboutUs">Know More</a>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default HomeHowWeWork;