import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from 'react-bootstrap/Image';
import ContactSmallBanner from "./../assets/images/contactus.png";
import ContactForm from "./ContactFrom";

const Contact = () => {
    return (
        <div className="main-container contact-us-container">
            <div className="contact-us-container_banner banner-container">
                <Container>
                    <Row>
                        <Col sm={7}>
                            <h1>Contact Us</h1>
                            {/* <h2>We are a leading IT company specializing in Java-based technology development, AWS cloud services, DevOps solutions, database technology, and web development.</h2> */}
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="inner-content">
                <Container>
                    <Row>
                        <Col>
                            <h2>Address</h2>
                            <Row>
                                <Col lg={5}>
                                    <p>P-219,Ground Floor,Airoli
                                        <br />Smt Sushiladevi Deshmukh Vidyalaya road
                                        <br />Navi Mumbai-400708
                                    </p>
                                </Col>
                                <Col lg={4}>
                                    <p>+91 98331 48679
                                        <br />+91 79774 72743
                                        <br />+91 88501 23700
                                    </p>
                                </Col>
                                <Col lg={3}>
                                    <p>noreply@stadwtech.com</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="grey-container inner-content">
                <Container>
                    <Row className="d-flex align-items-center">

                        <Col md={7}>
                            <Image src={ContactSmallBanner} alt="" fluid />
                        </Col>
                        <Col md={5}>
                            <Card className="banner-card">
                                <Card.Body>
                                    <ContactForm />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
export default Contact;