import Container from "react-bootstrap/Container";
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from 'react-bootstrap/Card';
import JavaDevelopmentImage from './../assets/images/home-services-banner/home-service-1.jpg';
import AWSImage from './../assets/images/home-services-banner/home-service-2.jpg';
import MobileAppDevelopmentImage from './../assets/images/home-services-banner/home-service-3.jpg';
import WebsiteDevelopmentImage from './../assets/images/home-services-banner/home-service-4.jpg';
import SEOImage from './../assets/images/home-services-banner/home-service-5.jpg';
import DigitalMarketingImage from './../assets/images/home-services-banner/home-service-6.jpg';
import CloudTechnologyImage from './../assets/images/home-services-banner/home-service-7.jpg';
import ScrollAnimation from "react-animate-on-scroll";

const HomeOurServices = () => {
    return (
        <div className="home-our-services">
            <Container>
                <Row className="d-flex  justify-content-center">
                    <Col sm={12} md={8} className="text-center">
                        <h1>Services</h1>
                        <h2>We work by creating win-win relationship with our customers.</h2>
                    </Col>
                </Row>
                <Row className="d-flex  justify-content-center py-5">
                    <Col xs={12} sm={6} md={4} lg={3}>
                        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                            <Card>
                                <Card.Img variant="top" src={JavaDevelopmentImage} />
                                <Card.Body className="text-center">
                                    <Card.Title>Java Development</Card.Title>
                                    <Card.Text>
                                        With years of expertise in Java development, our team is dedicated to delivering robust, scalable, and efficient applications tailored to meet your unique needs. Transform your business processes with scalable enterprise solutions built on Java technologies.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </ScrollAnimation>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3}>

                        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                            <Card>
                                <Card.Img variant="top" src={AWSImage} />
                                <Card.Body className="text-center">
                                    <Card.Title>AWS</Card.Title>
                                    <Card.Text>
                                        We conduct a comprehensive assessment of your business requirements and goals to develop a tailored AWS solution strategy. Our team designs a scalable and resilient architecture that leverages AWS services to meet your specific needs and objectives.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </ScrollAnimation>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3}>

                        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                            <Card>
                                <Card.Img variant="top" src={MobileAppDevelopmentImage} />
                                <Card.Body className="text-center">
                                    <Card.Title>App Development</Card.Title>
                                    <Card.Text>
                                        Validate your app idea by researching the market. Identify your target audience, analyze competitors, and assess demand for similar apps. Create a detailed plan for your app, including its features, functionality, and user interface. Use wireframing tools to sketch out the layout and flow of your app.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </ScrollAnimation>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3}>

                        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                            <Card>
                                <Card.Img variant="top" src={WebsiteDevelopmentImage} />
                                <Card.Body className="text-center">
                                    <Card.Title>Website Development</Card.Title>
                                    <Card.Text>
                                        We understand that every business is different. That's why we take the time to listen to your needs, preferences, and goals to create a customized website that reflects your brand identity and resonates with your target audience.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </ScrollAnimation>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3}>

                        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                            <Card>
                                <Card.Img variant="top" src={SEOImage} />
                                <Card.Body className="text-center">
                                    <Card.Title>SEO</Card.Title>
                                    <Card.Text>
                                        We integrate search engine optimization (SEO) best practices into every aspect of yourwebsite, from its structure and content to its meta tags and URLs. This helps improve your website's visibility on search engines and drives organic traffic to your site. 
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </ScrollAnimation>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3}>

                        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                            <Card>
                                <Card.Img variant="top" src={DigitalMarketingImage} />
                                <Card.Body className="text-center">
                                    <Card.Title>Digital Marketing</Card.Title>
                                    <Card.Text>
                                        We understand that every business is unique. That's why we take a personalized approach to digital marketing, developing customized strategies tailored to your specific goals, industry, and target audience.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </ScrollAnimation>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3}>

                        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                            <Card>
                                <Card.Img variant="top" src={CloudTechnologyImage} />
                                <Card.Body className="text-center">
                                    <Card.Title>Cloud Technology</Card.Title>
                                    <Card.Text>
                                        With years of experience in the industry, our team of cloud experts has the knowledge and skills to deliver cutting-edge solutions tailored to your unique requirements. Whether you're migrating to the cloud, optimizing your infrastructure, or developing cloud-native applications, we're here to help every step of the way.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>

        </div>
    )

}
export default HomeOurServices;