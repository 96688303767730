import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import logo from './../assets/images/logo-1.png'
import ListGroup from 'react-bootstrap/ListGroup';
import { FaFacebookSquare } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaYoutubeSquare } from "react-icons/fa";



const Footer = () => {
    return (
        <div className="footer" bg="dark" data-bs-theme="dark">
            <Container>
                <Row>
                    <Col sm={12} md={9} lg={8}>
                        <Row>
                            <Col sm={4} className="">
                                <h1>About Us</h1>

                                <ListGroup variant="flush">
                                    <ListGroup.Item><a  href="/AboutUs">Who we are</a></ListGroup.Item>
                                    <ListGroup.Item><a  href="/AboutUs">What we do</a></ListGroup.Item>
                                </ListGroup>
                            </Col>
                            <Col sm={4}>
                                <h1>Services</h1>
                                <ListGroup variant="flush">
                                    <ListGroup.Item><a  href="/Services">JAVA Development</a></ListGroup.Item>
                                    <ListGroup.Item><a  href="/Services">AWS and DevOps</a></ListGroup.Item>
                                    <ListGroup.Item><a  href="/Services">Web Development</a></ListGroup.Item>
                                    <ListGroup.Item><a  href="/Services">Cloud Technology</a></ListGroup.Item>
                                </ListGroup>
                            </Col>
                            <Col sm={4}>
                                <h1>Contact</h1>
                                <ListGroup variant="flush">
                                    <ListGroup.Item><a  href="/ConnectWithUs">Enquiry</a></ListGroup.Item>
                                </ListGroup>
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12} md={3} lg={4} className="text-center">
                        <img src={logo} className="brand-logo"></img>
                        <div className="social-media d-flex">
                            {/* <FontAwesomeIcon icon={faFacebook} /> */}
                            <FaFacebookSquare />
                            <FaTwitterSquare />
                            <FaInstagramSquare />
                            <FaYoutubeSquare />
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="footer-copyright">
                <div>© 2023 STADWTECH Technology Private Limited</div>
            </div>
        </div>
    )
}
export default Footer;