import BannerSlide from "./BannerSlide";
import HomeHowWeWork from "./HomeHowWeWork";
import HomeOurServices from "./HomeOurServices";
import HomeWhoWeAre from "./HomeWhoWeAre";

const HomeContent = () => {
    return (
        <div className="main-container">
            <BannerSlide />
            <HomeWhoWeAre />
            <HomeOurServices />
            <HomeHowWeWork />
        </div>
    )
}
export default HomeContent;