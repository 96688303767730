import { Card, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from 'react-bootstrap/Image';

import { FaRegLightbulb, FaMixcloud, FaCogs } from "react-icons/fa";
import ContentBanner from "./../assets/images/how-we-work.png";
import ScrollAnimation from "react-animate-on-scroll";

const AboutUs = () => {
    return (
        <div className="main-container about-us-container">
            <div className="about-us-container_banner banner-container">
                <Container>
                    <Row>
                        <Col lg={7}>
                            <h1>About Us</h1>
                            <h2>We are a leading IT company specializing in Java-based Technology Development, AWS Cloud Services, DevOps Solutions, Database Technology, and Web Development.</h2>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="inner-content">
                <Container>
                    <Row>
                        <Col>
                            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                                <h2>We have deep technical expertise</h2>
                                <p> In web development, we create visually appealing and user-friendly websites that drive online presence. Our services include front-end and back-end development, responsive design, e-commerce solutions, and CMS implementation. We pride ourselves on delivering top-notch solutions that drive business growth, improve efficiency, and enhance customer experiences. Our commitment to excellence, customer satisfaction, and staying ahead of the technological curve sets us apart as your trusted IT partner.</p>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                                <div className="about-services">
                                    <Row>
                                        <Col lg={4}>
                                            <Card>
                                                <Card.Body>
                                                    <div className="icon">
                                                        <FaRegLightbulb />
                                                    </div>
                                                    <p> In Java-based technology development, we excel in creating robust, scalable, and high-performance applications. Our talented developers possess the expertise to handle custom software development, application modernization, and seamless integration solutions.</p>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col lg={4}>
                                            <Card>
                                                <Card.Body>
                                                    <div className="icon">
                                                        <FaMixcloud />
                                                    </div>
                                                    <p> As an AWS Partner, we provide cutting-edge cloud solutions to help businesses harness the power of AWS. From migrating infrastructure to optimizing cloud architecture, our certified AWS experts ensure secure, reliable, and cost-effective cloud environments for your applications and data.</p>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col lg={4}>
                                            <Card>
                                                <Card.Body>
                                                    <div className="icon">
                                                        <FaCogs />
                                                    </div>
                                                    <p> With our DevOps services, we streamline the development and deployment process, enabling faster time to market and improved collaboration. Our DevOps engineers specialize in automation, CI/CD, and IaC, helping you accelerate innovation and improve software delivery.</p>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                                <p> Efficient data management is crucial, and our database technology expertise covers both relational and non-relational databases. We offer database design, optimization, performance tuning, and data migration services, ensuring secure and well-structured data management. With our unwavering commitment to excellence and customer satisfaction, we aim to be your trusted IT partner. Contact us today to explore how our Java-based technology development, AWS cloud technology, DevOps, database technology, and web development services can propel your business forward.</p>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="grey-container inner-content">
                <Container>
                    <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <Row className="d-flex align-items-center">
                            <Col lg={7}>
                                <img src={ContentBanner} alt="" />
                            </Col>
                            <Col lg={5}>
                                <Card className="banner-card">
                                    <Card.Body>
                                        <p>With a team of highly skilled professionals and a wealth of experience in the industry, we are dedicated to delivering innovative and reliable solutions to meet the unique needs of our clients.</p>
                                        <p>Contact us today to discuss how we can help you leverage the power of Java-based technology, AWS cloud, DevOps, database technology, and web development for your business success.</p>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </ScrollAnimation>
                </Container>
            </div>
            <div className="inner-content business-statergy">
                <Container>
                    <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                        <Row className="d-flex justify-content-center">
                            <Col lg={9}>
                                <h2>Business Strategy</h2>
                                <p>Our small IT company aims to become a leading provider of Java-based technology development, AWS Cloud Technology, DevOps, Database Technology, and Web Development Services.</p>

                                <p>We will achieve this by delivering exceptional quality solutions, exceeding client expectations, and maintaining a customer-centric approach. Our focus on customer satisfaction, technical expertise, and efficient project delivery will differentiate us and establish our reputation as a trusted partner in the IT industry.</p>
                            </Col>
                            {/* <Col sm={6}>
                            <Image src={BusinessStatergyBanner} alt=""  rounded fluid />
                        </Col> */}
                        </Row>
                    </ScrollAnimation>
                </Container>
            </div>
        </div>
    )
}
export default AboutUs;