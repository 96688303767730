import { Card, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';

import ContentBanner from "./../assets/images/how-we-work.png";
import JavaDevelopmentBanner from './../assets/images/java-development.jpg';
import AWSandDevopsBanner from './../assets/images/web-development.jpg';
import WebDevelopmentBanner from './../assets/images/web-development-1.jpg';
import ScrollAnimation from "react-animate-on-scroll";

const Services = () => {
    return (
        <div className="main-container services-container">
            <div className="services-container_banner banner-container">
                <Container>
                    <Row>
                        <Col lg={7}>
                            <h1>Services</h1>
                            <h2>In web development, we create visually appealing and user-friendly websites that drive online presence. Our services include front-end and back-end development, responsive design, e-commerce solutions, and CMS implementation.</h2>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row>
                    <Col><div className="inner-content">At <span className=" brand-name">STADWTECH</span> , we provide a wide range of comprehensive IT services to meet your business needs. Our dedicated team of professionals is committed to delivering top-notch solutions that empower your organization to thrive in the digital age.</div></Col>
                </Row>
            </Container >
            <div className="inner-content java-development-content">
                <Container>
                    <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <Row className="d-flex align-items-center my-lg-5">

                            <Col lg={7}>
                                <Card className="banner-card theme-banner-card">
                                    <Card.Body>
                                        <h2>JAVA Development</h2>
                                        <p> Our team of skilled Java developers stays up-to-date with the latest trends and technologies, enabling us to deliver high-quality Java development services that meet the evolving needs of our clients.</p>

                                        <Row>
                                            <Col md={6}><ListGroup variant="flush">
                                                <ListGroup.Item>Custom Software Development</ListGroup.Item>
                                                <ListGroup.Item>Application Modernization</ListGroup.Item>
                                                <ListGroup.Item>Enterprise Java Development</ListGroup.Item>
                                                <ListGroup.Item>Java Web Development</ListGroup.Item>
                                            </ListGroup>  </Col>
                                            <Col md={6}><ListGroup variant="flush">
                                                <ListGroup.Item>Java Mobile App Development</ListGroup.Item>
                                                <ListGroup.Item>Integration Services</ListGroup.Item>
                                                <ListGroup.Item>Performance Optimization</ListGroup.Item>
                                                <ListGroup.Item>Maintenance and Support</ListGroup.Item>
                                            </ListGroup>  </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col lg={5}>
                                <Image src={JavaDevelopmentBanner} alt="" rounded fluid />
                            </Col>
                        </Row>
                    </ScrollAnimation>
                </Container>
            </div>
            <div className="grey-container inner-content aws-devops-content">
                <Container>
                    <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <Row className="d-flex align-items-center">

                            <Col lg={6}>
                                <Image src={AWSandDevopsBanner} alt="" rounded fluid />
                            </Col>
                            <Col lg={6}>
                                <Card className="banner-card">
                                    <Card.Body>
                                        <h2>AWS and DevOps</h2>
                                        <p>In AWS and DevOps, our company offers a range of services to help businesses leverage the power of cloud computing and streamline their software development and deployment processes.</p>

                                        <Row>
                                            <Col md={6}>
                                                <ListGroup variant="flush">
                                                    <ListGroup.Item>Cloud Infrastructure Setup</ListGroup.Item>
                                                    <ListGroup.Item>AWS Architecture Design</ListGroup.Item>
                                                    <ListGroup.Item>Serverless Computing</ListGroup.Item>
                                                    <ListGroup.Item>Cloud Migration</ListGroup.Item>
                                                    <ListGroup.Item>Continuous Integration and Continuous Deployment (CI/CD)</ListGroup.Item>
                                                </ListGroup>
                                            </Col>
                                            <Col md={6}><ListGroup variant="flush">
                                                <ListGroup.Item>Infrastructure as Code (IaC)</ListGroup.Item>
                                                <ListGroup.Item>Configuration Management</ListGroup.Item>
                                                <ListGroup.Item>Monitoring and Performance Optimization</ListGroup.Item>
                                                <ListGroup.Item>Security and Compliance</ListGroup.Item>
                                            </ListGroup>  </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </ScrollAnimation>
                </Container>
            </div>
            <div className="inner-content web-development-content">
                <Container>
                    <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <Row className="d-flex align-items-center my-lg-5">

                            <Col lg={7}>
                                <Card className="banner-card theme-banner-card">
                                    <Card.Body>
                                        <h2>Web Development</h2>
                                        <p>In web development, our company offers a comprehensive range of services to help businesses establish a strong online presence and create engaging and user-friendly websites.</p>

                                        <Row>
                                            <Col md={6}><ListGroup variant="flush">
                                                <ListGroup.Item>Website Design and Development</ListGroup.Item>
                                                <ListGroup.Item>Front-End Development</ListGroup.Item>
                                                <ListGroup.Item>Back-End Development</ListGroup.Item>
                                                <ListGroup.Item>E-commerce Development</ListGroup.Item>
                                                <ListGroup.Item>Content Management Systems (CMS)</ListGroup.Item>
                                            </ListGroup>  </Col>
                                            <Col md={6}><ListGroup variant="flush">
                                                <ListGroup.Item>Web Application Development</ListGroup.Item>
                                                <ListGroup.Item>Website Maintenance and Support</ListGroup.Item>
                                                <ListGroup.Item>Search Engine Optimization (SEO)</ListGroup.Item>
                                                <ListGroup.Item>Digital Marketing</ListGroup.Item>
                                            </ListGroup>  </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col lg={5}>
                                <Image src={WebDevelopmentBanner} alt="" rounded fluid style={{ background: 'black' }} />
                            </Col>
                        </Row>
                    </ScrollAnimation>
                </Container>
            </div>
            <div className="inner-content services-data">
                <Container>

                    <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                        <Row className="d-flex justify-content-center">
                            <Col lg={9}>
                                <h2>We offer a comprehensive range of services in Java-based technology development, AWS cloud technology, DevOps, database technology, and web development.</h2>
                                <p>Our expertise in Java development includes custom software development, application modernization, and enterprise Java development. We provide AWS cloud services such as cloud infrastructure setup, architecture design, and serverless computing. With DevOps, we offer continuous integration and deployment, infrastructure as code, and performance optimization. Our database technology services cover design, optimization, and data migration. In web development, we specialize in front-end and back-end development, responsive design, e-commerce solutions, and content management systems.</p>

                                <Button variant="outline-success">Know More</Button>
                            </Col>
                            {/* <Col sm={6}>
                            <Image src={BusinessStatergyBanner} alt=""  rounded fluid />
                        </Col> */}
                        </Row>
                    </ScrollAnimation>
                </Container>
            </div>
        </div >
    )
}
export default Services;